<template>
  <div id="contact"></div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "VueContact",
  components: {},
  mounted() {
    // create a contact info
    const contactInfo = document.createElement('div');
    contactInfo.classList.add('container', 'mx-auto', 'px-4', 'py-8', 'md:py-16', 'lg:py-24', 'flex', 'flex-col', 'justify-center', 'items-center', 'text-center');
    contactInfo.innerHTML = `
      <h2 class="select-none text-3xl font-bold text-white">Contactez Nous</h2>
      <p class="text-lg text-gray-500 mt-4">Vous avez des questions ? N'hésitez pas à nous contacter directement. Notre équipe vous répondra dans les plus brefs délais.</p>
      <div class="flex flex-col md:flex-row mt-8">
        <div class="flex flex-col items-center md:items-start md:mr-8">
          <div class="flex items-center">
            <svg class="w-6 h-6 text-[#e30713]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
<!--            <svg class="w-6 h-6 text-[#e51c23]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4l9 16m0 0l9-16m-9 16V4"></path></svg>-->
            <span class="select-none ml-2 text-white">Adresse Mail</span>
          </div>
          <span class="select-all text-gray-300 mt-2">client@Hellogift.club</span>
        </div>
        <div class="flex flex-col items-center md:items-start mt-4 md:mt-0">
          <div class="flex items-center">
            <svg class="w-6 h-6 text-[#e30713]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
            <span class="select-none ml-2 text-white">Téléphone</span>
          </div>
          <span class="select-all text-gray-300 mt-2">+33 7 82 39 56 40</span>
        </div>
      </div>
    `;
    contactInfo.id = 'contact-info';
    document.getElementById('contact').appendChild(contactInfo);
  },
}
</script>

<style scoped>

</style>