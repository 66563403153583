import { createApp } from 'vue'
import App from './App.vue'

import './input.css';
import 'flowbite';

createApp(App).mount('#app')

if (location.protocol !== 'https:') {
    location.replace(`https:${location.href.substring(location.protocol.length)}`);
}


let link = document.querySelectorAll("#Link");
let Home = document.getElementById("Home");
link.forEach((item) => {
    item.addEventListener("click", () => {
        Home.scrollIntoView({ behavior: "smooth" });
    });
});
// if click on #Link2 scroll to About
let link2 = document.querySelectorAll("#SecondLink");
let About = document.getElementById("About");
link2.forEach((link) => {
    link.addEventListener("click", () => {
        About.scrollIntoView({
            behavior: "smooth",
        });
    });
});

// if click on #Link3 scroll to Contact
let link3 = document.querySelectorAll("#ThirdLink");
let Contact = document.getElementById("Contact");
link3.forEach((link) => {
    link.addEventListener("click", () => {
        Contact.scrollIntoView({
            behavior: "smooth",
        });
    });
});


// remove drag and drop image
let img = document.querySelectorAll("img");
img.forEach((item) => {
    item.addEventListener("dragstart", (e) => {
        e.preventDefault();
    });
});