<template>
  <nav id="Home" class="bg-slate-200 px-2 sm:px-4 py-2.5 rounded z-50">
    <div class="container flex flex-wrap justify-between items-center mx-auto">
      <img src="../assets/logo.png" class="w-22 h-12" alt="Logo">
      <button id="hamburger" data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-[#00000] rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-default" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
      </button>
      <div class="hidden w-full md:block md:w-auto" id="navbar-default">
        <ul class="flex flex-col p-4 mt-4 text-center  rounded-lg  md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 sm:border-0">
          <li>
            <a id="Link" class="link-active select-none block py-2 pr-4 pl-3 md:bg-transparent xl:bg-transparent  text-gray-700 rounded md:p-0 hover:cursor-pointer" aria-current="page">
              Accueil
            </a>
          </li>
          <li>
            <a id="SecondLink" class="link select-none block py-2 pr-4 pl-3 text-gray-700 rounded md:border-0 md:p-0 hover:cursor-pointer">Information</a>
          </li>
          <li>
            <a id="ThirdLink" class="link select-none block py-2 pr-4 pl-3 text-gray-700 rounded md:border-0 md:p-0 hover:cursor-pointer">Nous Contacter</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Navbar",
  components: {},
  mounted() {
    // eslint-disable-next-line no-undef
    const collapse = document.querySelectorAll('[data-collapse-toggle]');
    collapse.forEach((item) => {
      item.addEventListener('click', () => {
        const target = item.getAttribute('data-collapse-toggle');
        const targetElement = document.getElementById(target);
        targetElement.classList.toggle('hidden');
      });
    });

    // onclick on hamburger icon change to cross
    // change hamburger svg to x if navbar is open
    const hamburger = document.getElementById('hamburger');
    const navbar = document.getElementById('navbar-default');
    hamburger.addEventListener('click', () => {
      if (navbar.classList.contains('hidden')) {
        hamburger.innerHTML = '<span class="sr-only">Open main menu</span><svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>';
      } else {
        hamburger.innerHTML = '<span class="sr-only">Close main menu</span><svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414L11.414 10l2.293 2.293a1 1 0 01-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>';
      }
    });
  },
}
</script>

<style scoped>
.link-active::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: #e30713;
  transition: width .3s;
}

.link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #e30713;
  transition: width .3s;
}

.link:hover::after {
  width: 100%;
  transition: width .3s;
}

@media (max-width: 768px) {
  .link::after {
    display: none;
    width: 0;
  }
}
</style>