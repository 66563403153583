<template>
  <div class="pt-6 pb-0  mx-auto">
    <h2 class="text-center text-4xl font-bold text-[#656565] p-8">Participez à des sondages en ligne</h2>
    <p class="text-center text-[#8B8B8B] w-[80%]">
      Vous avez le pouvoir d'influencer le développement des produits et services que vous consommerez demain.
      Exprimez-vous en participant à des sondages rémunérés, des tests de produits et des visites mystères en échange
      d'argent ou de cartes cadeaux
    </p>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "About",
  components: {},
};
</script>

<style scoped>
p{
  margin: 15px auto;
}

</style>