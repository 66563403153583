<template>
  <div class="wrapper">
    <div class="loader"></div>
  </div>
  <Navbar />
  <main>
    <div class="header-bg">
      <div class="flex justify-center mt-[25rem] md:mt-[45rem] hover:cursor-pointer">
        <div class="scroll-downs">
          <div id="Scroll-down" class="mousey">
            <div class="scroller"></div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <section id="About">
    <About />
  </section>
  <section id="Formations">
    <Information />
  </section>
  <section id="Contact">
    <VueContact />
  </section>
  <FooterVue />
  <div id="toast-warning" className="hidden flex sticky bottom-5 right-5 items-center p-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
    <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
      <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path>
      </svg>
      <span className="sr-only">Warning icon</span>
    </div>
    <div className="ml-3 text-sm font-normal">Action Impossible !</div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import About from "@/Sections/About";
import Information from "@/Sections/Information";
import VueContact from "@/Sections/VueContact";
import FooterVue from "@/components/FooterVue";

export default {
  name: 'App',
  components: {FooterVue, Navbar, VueContact, Information, About},
  mounted() {
    console.log('JS Loaded !');
    // create button to up the page show when scroll
    const upButton = document.createElement('button');
    upButton.setAttribute('id', 'btn-up');
    upButton.setAttribute('class', 'btn-up');
    upButton.innerHTML = `
      <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M10.707 3.293a1 1 0 00-1.414 0L3.293 9.707a1 1 0 101.414 1.414L9 6.414V17a1 1 0 102 0V6.414l4.293 4.293a1 1 0 001.414-1.414l-6.414-6.414z" clip-rule="evenodd"></path>
      </svg>
    `;
    document.body.appendChild(upButton);
    // show button when scroll
    window.addEventListener('scroll', () => {
      const upButton = document.getElementById('btn-up');
      if (window.scrollY > 100) {
        upButton.classList.add('show');
      } else {
        upButton.classList.remove('show');
      }
    });
    // up the page when click
    upButton.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
    // collapse navbar
    const collapse = document.querySelectorAll('[data-collapse-toggle]');
    collapse.forEach((item) => {
      item.addEventListener('click', () => {
        const target = item.getAttribute('data-collapse-toggle');
        const targetElement = document.getElementById(target);
        targetElement.classList.toggle('hidden');
      });
    });

    // Remove only in prod
    // document.addEventListener('contextmenu', (e) => {
    //   e.preventDefault();
    //   return AlertMesssage();
    // });


    let ScrollDown = document.getElementById('Scroll-down');
    // scroll to id section when click on scroll down
    ScrollDown.addEventListener('click', () => {
      console.log('scroll down');
      window.scrollTo({
        top: 980,
        behavior: 'smooth',
      });
    });

    const fadeOut = () => {
      const loaderWrapper = document.querySelector('.wrapper');
      loaderWrapper.classList.add('fade');
    };

    window.addEventListener('load', fadeOut);

    // remove select
    // document.addEventListener('selectstart', (e) => {
    //   e.preventDefault();
    // });

    // remove F12 and Ctrl + Shift + I  and Ctrl + maj + c and cmd + maj + c
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 123 || (e.ctrlKey && e.shiftKey && e.keyCode === 73) || (e.ctrlKey && e.keyCode === 85) || (e.ctrlKey && e.shiftKey && e.keyCode === 67) || (e.metaKey && e.shiftKey && e.keyCode === 67)) {
        e.preventDefault();
        return AlertMesssage();
      }
    });

    function AlertMesssage() {
      const alert = document.getElementById('toast-warning');
      alert.classList.remove('hidden');
      setTimeout(() => {
        alert.classList.add('hidden');
      }, 3000);
    }
  }
}

</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Helvetica, sans-serif;
  background: #0d1117;
  overflow-x: hidden;
  max-width: 100%;
}

::selection {
  background: #e30713;
  color: #ffffff;
}

.wrapper {
  position: fixed;
  inset: 0;
  z-index: 9999;
  background-color: #ffffff;
  display: grid;
  place-items: center;
  transition: opacity 0.25s, visibility 1s;
}

.wrapper.fade {
  opacity: 0;
  visibility: hidden;
}

.wrapper .loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #e30713; /* Red */
  animation: loader 1s infinite linear;
}


@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*main h1 {*/
/*  text-shadow: 7px 2px black;*/
/*}*/

.header-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0.18)), url("./assets/Hellogift.png") no-repeat center center/cover;
  height: 93vh;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


@media (max-width: 640px) {
  .header-bg {
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("./assets/BG_Mobile.png") no-repeat center center/cover;
  }
}

.btn-up {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #1f2937;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
}

.btn-up.show {
  opacity: 1;
  visibility: visible;
}

.btn-up svg {
  width: 20px;
  height: 20px;
}

#About {
  position: relative;
  box-shadow: 0 1px 0 #dde0e7;
  background: #F5F6F8;
  color: #8B8B8B;
}

#Formations {
  background: #ffffff;
}

#About:after {
  color: #F5F6F8;
  position: relative;
  text-shadow: 0px 2px 0 #dde0e7;
  content: "\f0d7";
  font-family: FontAwesome;
  font-size: 2.9em;
  bottom: -37px;
  left: 50%;
  margin-left: -15px;
}

#Contact {
  background: #161b22;
  padding: 50px 0;
}

.scroll-downs {
  margin: auto;
  width :34px;
  height: 55px;
}

.mousey {
  width: 2px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #010202;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}

.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #010202;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}

@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}

</style>