<template>
  <footer class="p-4 bg-white rounded-lg shadow md:px-6 md:py-8">
    <div class="sm:flex sm:items-center sm:justify-between">
      <a class="flex items-center mb-4 sm:mb-0">
        <img src="../assets/logo.png" class="mr-3 h-8 select-none" alt="Hello-Gift Logo">
      </a>
      <ul class="flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0">
        <li>
          <a id="Link" class="mr-4 hover:text-[#e30713] hover:cursor-pointer md:mr-6 ">Acceuil</a>
        </li>
        <li>
          <a id="SecondLink" class="mr-4 hover:text-[#e30713] hover:cursor-pointer md:mr-6">Information</a>
        </li>
      </ul>
    </div>
    <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8">
    <span class="block text-sm text-gray-500 sm:text-center">© 2022 <a id="Link" class="hover:text-[#e30713] hover:cursor-pointer">Hello-Gift™</a>. All Rights Reserved.
    </span>
  </footer>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "FooterVue",
}
</script>

<style scoped>
</style>