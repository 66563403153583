<template>
  <div class="px-4 mx-auto">
    <div id="Tips" class="flex flex-col md:flex-row items-center justify-center top-0 p-0 xl:pt-0 xl:p-12 xl:pb-0">
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Information',
  mounted() {
    // make array of tips with 3 elements each
    const tips = [
      {
        icon: '<svg class="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>',
        title: 'Inscrivez-vous',
        description: 'Il vous faudra moins de 2 minutes pour vous inscrire',
      },
      {
        icon: '<svg class="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path></svg>',
        title: 'Répondez aux sondages',
        description: 'Faites valoir votre opinion et influencez les produits et services de demain',
      },
      {
        icon: '<svg class="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"></path></svg>',
        title: 'Profitez de vos récompenses',
        description: 'Votre participation vous rapporte des de l\'argent et des cadeaux.',
      },
    ];

    // loop through tips array with forEach and create a div for each tip
    tips.forEach((tip) => {
      const tipDiv = document.createElement('div');
      tipDiv.classList.add('flex', 'flex-col', 'items-center', 'justify-center', 'w-96', 'h-96', 'p-8', 'mx-4', 'my-4', 'md:px-6', 'md:py-8');

      // create div
      const iconDiv = document.createElement('div');
      iconDiv.classList.add('flex', 'items-center', 'justify-center', 'w-28', 'h-28', 'mb-4', 'text-4xl', 'text-white', 'rounded-full', 'bg-red-500');
      // create icon
      const tipIcon = document.createElement('div');
      tipIcon.innerHTML = tip.icon;

      // create title
      const tipTitle = document.createElement('h2');
      tipTitle.classList.add('text-center', 'text-2xl', 'font-bold', 'text-[#484848]', 'mb-4');
      tipTitle.innerText = tip.title;

      // create description
      const tipDescription = document.createElement('h3');
      tipDescription.classList.add('text-center','text-[#8B8B8B]', 'text-{130%}', 'w-[80%]');
      tipDescription.innerText = tip.description;

      // append icon, title and description to tipDiv
      iconDiv.appendChild(tipIcon);
      tipDiv.appendChild(iconDiv);
      tipDiv.appendChild(tipTitle);
      tipDiv.appendChild(tipDescription);

      // append tipDiv to Tips div
      document.getElementById('Tips').appendChild(tipDiv);
    });
  },
}
</script>

<style scoped>

</style>